import React, { useEffect, useState } from 'react';
import { AMIButton, AMIFormElement, AMIInput } from '../../ui-components/UiComponents';
import { isValidEmail } from '../../utilities/ValidationUtilities';
import logo from './express-fr8-logo.png';
import { setNewCognitoPassword } from '../cognitoAuthService';
import { useHistory } from 'react-router-dom';

const ExpressFr8ResetPassword = () => {

  const history = useHistory();

  const [showErrors, setShowErrors] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const email = params.get('email') as string;
    const name = params.get('name') as string;
    setEmail(email);
    setName(name);
  }, [])

  const onContinueClick = async() => {
    setIsSpinning(true);
    setShowErrors(true);
    if (confirmationCode && newPassword && confirmPassword && newPassword === confirmPassword) {
      try {
        const result = await setNewCognitoPassword(email, newPassword, confirmationCode);
        if (result) {
          window.alert("Your password has been reset. Please sign in on the next page.")
          history.push('/auth?password-reset=true');
        }
      } catch (error) {
        alert(`Sign in failed: ${error}`);
      }
    }
    setIsSpinning(false);
  }

  return (
    <div className="auth-page">

      <div className="auth-container">

        <img className="auth-container__logo" src={logo} alt="Logo"/>

          <p className="auth-container__text">Welcome {name}, please enter the <strong>confirmation code</strong> from the <strong>invitation email</strong> and then enter a <strong>new password</strong>.</p>

          <AMIFormElement
            label="Email"
            errorMessage={showErrors && !isValidEmail(email) ? "Please enter a valid email address" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-1"
              size="large"
              disabled
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Confirmation Code"
            errorMessage={showErrors && !confirmationCode ? "Please enter the confirmation code form the invitation email" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-2"
              size="large"
              value={confirmationCode}
              onChange={(event) => setConfirmationCode(event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="New Password"
            errorMessage={showErrors && !newPassword ? "Please enter a new password" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-3"
              size="large"
              type="password"
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Confirm New Password"
            errorMessage={showErrors && (!confirmPassword || newPassword !== confirmPassword) ? "Passwords must match" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-4"
              size="large"
              type="password"
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </AMIFormElement>


          <AMIButton
            variant="primary"
            size="large"
            type="submit"
            style={{width: "100%"}}
            isSpinning={isSpinning}
            onClick={onContinueClick}
          >
            Continue
          </AMIButton>
      </div>

    </div>
  )
}

export default ExpressFr8ResetPassword;