import React, { useEffect, useState } from 'react';
import {  AMICheckbox, AMIFormElement, AMIInput, AMIPopover, AMISelect, Icon } from '../../../../../ui-components/UiComponents';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';
import { getCountryInfo } from '../../../../../utilities/APIUtilities';
import { formatCounties } from '../../../../../utilities/HelperUtilities';
import { bookingActions } from '../../../../../features/bookingSlice';

const TemplateModalCountries: React.FC<{
  template: TemplateForm
  updateCountries: (field: string, value: any) => void
}> = ({
  template,
  updateCountries
}) => {

  const appData = useAppSelector(appDataSelector);
  const countries = appData.countries;
  const data = template.countries
  const dispatch = useAppDispatch();

  const [counties, setCounties] = useState<any>([]);
  const [isCountyPopoverVisible, setIsCountyPopoverVisible] = useState(false);

  useEffect(() => {
    handleCountryInfo();
  }, [data.origin.value])

  const handleCountryInfo = async() => {
    const countryInfo = await getCountryInfo(appData.apiConfig, template.countries.origin.value);
    if (countryInfo?.countiesStates?.length > 0) {
      setCounties(formatCounties(countryInfo.countiesStates));
    }
  }

  const onOriginCountyChange = (event: any) => {
    template.shipper.countyStateProvince = event.value;
    template.collection.countyStateProvince = event.value;
  }

  return (
    <div className="template-modal-general">
      <div className="template-modal__form__to-from">
        <AMIFormElement label="Send From Origin">
          <AMISelect
            name="field-1"
            size="large"
            isSearchable
            style={{
              width: '100%',
            }}
            defaultValue={data.origin}
            options={countries}
            isSpinningMessage={
              countries.length < 1
                ? 'Fetching countries'
                : 'Processing'
            }
            onChange={(event) => updateCountries("origin", event)}
          />
        </AMIFormElement>

        <AMIFormElement label="City/Town">
          <AMIInput
            name="send-from-origin-city-town"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.originCityTown}
            onChange={(event) => updateCountries("originCityTown", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement label="Postal/Zip Code">
          <AMIInput
            name="send-from-origin-postal-code"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.originPostalCode}
            onChange={(event) => updateCountries("originPostalCode", event.target.value)}
          />
        </AMIFormElement>

        {(data.origin.value === "US" || data.origin.value === "CA") && counties.length > 0 && (
          <AMIFormElement
            label={(
              <div>State/Province
                <span
                  onMouseEnter={() => setIsCountyPopoverVisible(true)}
                  onMouseLeave={() => setIsCountyPopoverVisible(false)}
                >
                  <Icon
                    name="CircleQuestion"
                    color="var(--secondary)"
                    style={{marginLeft: '4px'}}
                  />
                  <AMIPopover isVisible={isCountyPopoverVisible}>
                    If a state is not provided then UPS will not be able to quote for this shipment.
                  </AMIPopover>
                </span>
              </div>
            )}
          >
            <AMISelect
              name="origin-county"
              size="large"
              isSearchable
              style={{
                width: '100%',
              }}
              defaultValue={counties.find((item: any) => {
                return item.value === template.collection.countyStateProvince
              })}
              options={counties}
              onChange={(event) => onOriginCountyChange(event)}
            />
          </AMIFormElement>
        )}

        <AMICheckbox
          className="template-modal__form__to-from__checkbox"
          checked={data.originResidential}
          text="Residential address?"
          onChange={() => updateCountries("originResidential", !data.originResidential)}
        />

        <AMIFormElement label="Send To Destination">
          <AMISelect
            name="field-2"
            size="large"
            isSearchable
            style={{
              width: '100%',
            }}
            defaultValue={data.destination}
            options={countries}
            isSpinningMessage={
              countries.length < 1
                ? 'Fetching countries'
                : 'Processing'
            }
            onChange={(event) => updateCountries("destination", event)}
          />
        </AMIFormElement>

        <AMIFormElement label="City/Town">
          <AMIInput
            name="send-from-destination-city-town"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.destinationCityTown}
            onChange={(event) => updateCountries("destinationCityTown", event.target.value)}
          />
        </AMIFormElement>

        <AMIFormElement label="Postal/Zip Code">
          <AMIInput
            name="send-from-destination-postal-code"
            size="large"
            style={{
              width: '100%',
            }}
            value={data.destinationPostalCode}
            onChange={(event) => updateCountries("destinationPostalCode", event.target.value)}
          />
        </AMIFormElement>

        <AMICheckbox
          className="template-modal__form__to-from__checkbox"
          checked={data.destinationResidential}
          text="Residential address?"
          onChange={() => updateCountries("destinationResidential", !data.destinationResidential)}
        />

      </div>
    </div>
  )
}

export default TemplateModalCountries;