import React from 'react';
import { AMIFormElement, AMIInput, AMISelect } from '../../../../../ui-components/UiComponents';
import { LabelType, selectTitleFormatter } from '../../../../../utilities/HelperUtilities';
import { TemplateForm } from '../../../../../utilities/TemplateHandler';

const TemplateModalPreferences: React.FC<{
  template: TemplateForm;
  updatePreferences: (field: string, value: any) => void;
}> = ({
  template,
  updatePreferences
}) => {

  return (
    <div className="template-modal-preferences">
      <AMIFormElement
        label="Email Confirmation"
      >
        <AMIInput
          name="preferenceEmail"
          size="large"
          type="email"
          value={template.preferences.email}
          onChange={(event) => updatePreferences('email', event.target.value)}
        />
      </AMIFormElement>

      <AMIFormElement
        label="Label Type"
      >
        <AMISelect
          name="preferencesLabelType"
          size="large"
          defaultValue={{
            title: selectTitleFormatter(template.preferences.labelType),
            value: template.preferences.labelType
          }}
          options={[
            {title: 'PDF A4', value: LabelType.PDF_A4},
            {title: 'PDF 4x6', value: LabelType.PDF_4X6},
            {title: 'Thermal', value: LabelType.THERMAL}
          ]}
          onChange={(event) => updatePreferences('labelType', event.value)}
        />
      </AMIFormElement>
    </div>
  )

}

export default TemplateModalPreferences;