import dayjs from "dayjs";
import { Preferences } from "../../../features/customerSlice";
import { InputSenderDetails, ManifestBookingRequestParams } from "./ManifestBookingRequestHandler";
import { ImperialMetric, SpecialServiceType } from "../../../features/bookingSlice";
import { BookingRequestBody, PackageLocation } from "../../../utilities/APIUtilities";
import { generateLabel, LabelType } from "../../../utilities/HelperUtilities";

export enum LabelSize {
  PAPER_4X6 ='PAPER_4X6',
  STOCK_4X6 ='STOCK_4X6'
}

export interface CsvBooking {
  shipment_reference: string,
  shipment_type: string,
  item_name: string,
  value: number,
  weight: number,
  length: number,
  width: number,
  height: number,
  organisation: string,
  name: string,
  address_line_1: string,
  address_line_2?: string,
  city_town: string,
  county_state_province?: string,
  postal_code?: string,
  country_code: string,
  telephone: string,
  email?: string,
  residential: boolean,
  ddp: boolean,
  insurance: boolean,
  commodity_description: string,
  commodity_pieces: number,
  commodity_value_piece: string,
  commodity_weight: string,
  commodity_code: string
}

interface ContactDetails {
  name: string,
  telephone: string
}

interface Address {
  organisation: string,
  streetLines: string[],
  city: string,
  stateOrCounty?: string,
  postalCode?: string,
  countryCode: string,
  residential: boolean
}

interface NotificationDetails {
  email?: string | null
}

interface Weight {
  value: number,
  unit: string
}

interface Dimensions {
  length: number,
  width: number,
  height: number,
  unit: string
}

interface SenderDetails {
  contactDetails: ContactDetails,
  address: Address,
  notificationDetails: NotificationDetails
}

interface DeliveryDetails {
  contactDetails: ContactDetails,
  address: Address,
  notificationDetails: NotificationDetails
}

interface Parcel {
  weight: Weight,
  dimensions: Dimensions,
  description: string
}

interface Quantity {
  value: number,
  unit: string
}

interface UnitPrice {
  value: string,
  currency: string
}

interface Amount {
  value: number,
  currency: string
}

interface InvoiceDetails {
  termsOfSale: string
}

interface Commodities {
  countryOfOrigin: string,
  description: string,
  quantity: Quantity,
  unitPrice: UnitPrice,
  weight: Weight,
  commodityCode: string
}

interface CustomsDetails {
  commodities?: Commodities[],
  amount: Amount,
  invoiceDetails?: InvoiceDetails
}

interface Label {
  type: LabelType,
  size: LabelSize
}

interface Booking {
  shipmentDateTime: string,
  amiCarrierServiceName: string,
  customerShippingReference: string,
  customerCloseTime: string,
  senderDetails: SenderDetails,
  collectionDetails: SenderDetails,
  deliveryDetails: DeliveryDetails,
  parcels: Parcel[],
  customsDetails: CustomsDetails,
  shipmentType: string,
  label: Label,
  remarks: string,
  contentDescription: string
}

interface ManifestBooking {
  customerAccountNumber: string,
  // ready date plus pickUpFrom
  shipmentDateTime: string,
  customerCloseTime: string,
  senderDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode?: string,
      streetLines: string[],
      city: string,
      stateOrCounty?: string,
    },
    notificationDetails: {
      email: string
    }
  },
  collectionDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode?: string,
      streetLines: string[],
      city: string,
      stateOrCounty?: string,
      residential: boolean
    },
    notificationDetails: {
      email: string
    }
  } | null,
  deliveryDetails: {
    contactDetails: {
      name: string,
      telephone: string
    },
    address: {
      organisation: string,
      countryCode: string,
      postalCode?: string,
      streetLines: string[],
      city: string,
      stateOrCounty?: string,
      residential: boolean
    },
    notificationDetails: {
      email: string
    }
  },
  packageLocation: PackageLocation,
  parcels: {
    dimensions: {
      length: number,
      width: number,
      height: number,
      unit: string
    },
    weight: {
      value: number,
      unit: string
    },
    description: string
  }[],
  customerShippingReference: string,
  contentDescription: string,
  label: {
      type: string,
      size: string
  },
  shipmentType: string,
  remarks: string
  amiCarrierServiceName: string,
  specialServices: SpecialServiceType[] | null;
  customsDetails: {
    // exportComplianceStatement: string | null;
    // b13AFilingOption: string | null;
    // statementType: string | null;
    amount: {
      value: number,
      currency: string;
    },
    invoiceDetails: {
      // declarationNotes?: string,
      // declarationStatementRequested?: {
      //   value: boolean,
      //   type: string
      // },
      termsOfSale: string,
      // taxDetails?: [
      //   {
      //     type: string,
      //     party: string,
      //     value: string
      //   }
      // ],
      // exportReason: string,
      // specialInstructions?: string,
      // declaredCharges: [],
      // invoiceDate: string | null,
      // invoiceNumber: string | null,
      // cpc: string | null
    },
    // electronicTradeDetails?: {
    //   uploadedDocuments: {
    //     type: ElectronicTradeDocumentType,
    //     contentType: ElectronicTradeDocumentFormatType,
    //     documentContent: string
    //   }[]
    // } | null,
    // importerOfRecordDetails: {
    //   deliveryAddress: boolean,
    //   contactDetails: {
    //     name: string,
    //     telephone: string
    //   },
    //   address: {
    //     organisation: string,
    //     countryCode: string,
    //     postalCode?: string,
    //     streetLines: string[],
    //     city: string,
    //     stateOrCounty?: string,
    //   }
    // },
    commodities: {
      commodityCode: string | null,
      description: string | null,
      countryOfOrigin: string | null,
      quantity: {
        value: string,
        unit: string
      } | null,
      unitPrice: {
        value: string,
        currency: string
      } | null,
      weight: {
        value: string,
        unit: string
      } | null
    }[] | null
  }
}

export interface ManifestBookingRequest {
  manifestEmailAddress: string,
  partyId: string,
  noOfBookings: number,
  bookings: ManifestBooking[]
}

export const createManifestBookingRequest = (params: ManifestBookingRequestParams) => {
  const groupedCsvData = groupByShipmentReference(params.csvData);

  const manifestBookingRequest: ManifestBookingRequest = {
    manifestEmailAddress: params.email,
    partyId: params.tmffPartyId,
    noOfBookings: Object.keys(groupedCsvData).length,
    bookings: createBookings(groupedCsvData, params)
  }

  return manifestBookingRequest;
}

const groupByShipmentReference = (csvData: CsvBooking[]) => {
  const keyForGrouping = "shipment_reference";
  return csvData.reduce((result, next) => {
    const key = next[keyForGrouping];
    // @ts-ignore
    result[key] = result[key]?.length ? [...result[key], next] : [next];
    return result;
  }, {});
}

const createBookings = (groupedCsvData: {}, params: ManifestBookingRequestParams) => {
  const customer = params.customer;
  let bookings: ManifestBooking[] = [];

  Object.values(groupedCsvData).forEach(value => {
    const csvBooking: {[index: string]: any} = value as Booking[];
    const booking: ManifestBooking = {
      customerAccountNumber: customer.creditCheck.tmffPartyId,
      shipmentDateTime: generateShipmentDateTime(params.readyDate),
      amiCarrierServiceName: params.service,
      customerCloseTime: "17:00",
      senderDetails: generateSenderDetails(params.email, params.senderDetails),
      // TODO populate collection details properly (won't always be sender details in future versions)
      collectionDetails: generateSenderDetails(params.email, params.senderDetails),
      deliveryDetails: generateDeliveryDetails(csvBooking[0]),
      packageLocation: PackageLocation.NONE,
      parcels: generateParcels(csvBooking, params.customerPreferences, params.imperialOrMetric),
      customerShippingReference: csvBooking[0].shipment_reference,
      contentDescription: csvBooking[0].item_name,
      label: generateLabel(params.labelType, customer),
      shipmentType: csvBooking[0].shipment_type,
      remarks: '',
      specialServices: populateSpecialServices(csvBooking[0]),
      // @ts-ignore
      customsDetails: generateCustomsDetails(csvBooking, params)
    };

    bookings.push(booking);
  });

  return bookings;
}

const populateSpecialServices = (booking: CsvBooking) => {
  let specialServices: SpecialServiceType[] = [];

  if (booking.ddp) specialServices.push(SpecialServiceType.DELIVERED_DUTY_PAID);
  if (booking.insurance) specialServices.push(SpecialServiceType.INSURANCE);

  return specialServices.length > 0 ? specialServices : null;
}

const generateShipmentDateTime = (readyDate: number) => {
  return dayjs(readyDate, "YYYY-MM-DD")
    .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
}

const generateSenderDetails = (email: string, senderDetails: InputSenderDetails) => {
  return {
    contactDetails: generateContactDetails(senderDetails.name, senderDetails.telephone),
    address: formatSenderAddress(senderDetails),
    notificationDetails: {
      email
    }
  };
}

const generateDeliveryDetails = (csvBooking: CsvBooking) => {
  return {
    contactDetails: generateContactDetails(csvBooking.name, csvBooking.telephone),
    address: generateAddress(csvBooking),
    notificationDetails: {
      email: csvBooking.email ? csvBooking.email : ''
    }
  };
}

const formatSenderAddress = (customerAddress: InputSenderDetails) => {
  let address: Address = {
    organisation: customerAddress.organisation,
    streetLines: generateStringLines(customerAddress.addressLine1, customerAddress.addressLine2),
    city: customerAddress.cityTown,
    stateOrCounty: customerAddress.countyStateProvince ? customerAddress.countyStateProvince : '',
    countryCode: customerAddress.countryISO,
    postalCode: customerAddress.postalCode ? customerAddress.postalCode : '',
    residential: customerAddress.residential
  };

  return address;
}

const generateAddress = (csvBooking: CsvBooking) => {
  let address: Address = {
    organisation: csvBooking.organisation,
    streetLines: generateStringLines(csvBooking.address_line_1, csvBooking.address_line_2),
    city: csvBooking.city_town,
    stateOrCounty: csvBooking.county_state_province ? csvBooking.county_state_province : '',
    countryCode: csvBooking.country_code,
    residential: csvBooking.residential
  };

  if (csvBooking.postal_code) address.postalCode = csvBooking.postal_code

  return address;
}

const generateStringLines = (addressLine1: string, addressLine2: string | undefined) => {
  let streetLines = [];
  if (addressLine1) streetLines.push(addressLine1)
  if (addressLine2) streetLines.push(addressLine2)
  return streetLines;
}

const generateContactDetails = (name: string, telephone: string) => {
  return {
    name: name,
    telephone: telephone
  };
}

const generateParcels = (csvBookings: { [index: string]: any }, customerPreferences: Preferences, imperialOrMetric: ImperialMetric) => {
  const parcels: Parcel[] = [];

  for (let key in csvBookings) {
    const parcel: Parcel = {
      weight: generateWeight(csvBookings[key].weight, imperialOrMetric),
      dimensions: generateDimensions(csvBookings[key].length, csvBookings[key].width, csvBookings[key].height, customerPreferences, imperialOrMetric),
      description: csvBookings[key].item_name
    }
    parcels.push(parcel);
  }

  return parcels;
}

const generateWeight = (weightValue: string, imperialOrMetric: ImperialMetric) => {
  return {
    value: +weightValue,
    unit: imperialOrMetric === ImperialMetric.METRIC ? 'KG' : 'LB'
  };
}

const generateDimensions = (lengthValue: number, widthValue: number, heightValue: number, customerPreferences: Preferences, imperialOrMetric: ImperialMetric) => {
  let length = lengthValue;
  if (length === 0) {
    length = customerPreferences.lengthWidthHeight.length;
  }

  let width = widthValue;
  if (width === 0) {
    width = customerPreferences.lengthWidthHeight.width;
  }

  let height = heightValue;
  if (height === 0) {
    height = customerPreferences.lengthWidthHeight.height;
  }

  return {
    length: length,
    width: width,
    height: height,
    unit: imperialOrMetric === ImperialMetric.METRIC ? 'CM' : 'IN'
  };
}

const generateCustomsDetails = (csvBooking: CsvBooking[], params: ManifestBookingRequestParams) => {
  if (params.isInternational) {
    return {
      commodities: generateCommodities(csvBooking, params),
      amount: generateAmount(csvBooking, params.currencyCode),
      invoiceDetails: generateInvoiceDetails(csvBooking[0])
    };
  } else {
    return {
      amount: generateAmount(csvBooking, params.currencyCode)
    }
  }
}

const generateCommodities = (csvBooking: CsvBooking[], params: ManifestBookingRequestParams) => {
  const commodities: Commodities[] = [];

  for (let key in csvBooking) {
    const commodity: Commodities = {
      countryOfOrigin: params.customer.countryOfResidence.value,
      description: csvBooking[key].commodity_description,
      quantity: generateQuantity(csvBooking[key].commodity_pieces),
      unitPrice: generateUnitPrice(csvBooking[key].commodity_value_piece, params.currencyCode),
      weight: generateWeight(csvBooking[key].commodity_weight, params.imperialOrMetric),
      commodityCode: csvBooking[key].commodity_code
    };
    commodities.push(commodity);
  }

  return commodities;
}

const generateQuantity = (value: number) => {
  return {
    value,
    unit: 'PCS'
  };
}

const generateUnitPrice = (value: string, currencyCode: string) => {
  return {
    value,
    currency: currencyCode
  };
}

const generateAmount = (booking: CsvBooking[], currencyCode: string) => {
  const totalShipmentValue = booking.reduce((a: any, b: any) => a + b.value, 0)
  return {
    value: totalShipmentValue,
    currency: currencyCode
  };
}

const generateInvoiceDetails = (booking: CsvBooking) => {
  return {
    termsOfSale: booking.ddp ? 'DDP' : 'DAP'
  };
}
