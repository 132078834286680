import { AuthFlowType, ChallengeNameType, CognitoIdentityProviderClient, ConfirmForgotPasswordCommand, ForgotPasswordCommand, InitiateAuthCommand, RespondToAuthChallengeCommand } from '@aws-sdk/client-cognito-identity-provider';
import config from './config.json';

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const verifyUser = async (username: string, password: string) => {
  const params = {
    AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { Session } = await cognitoClient.send(command);
    if (Session) {
      sessionStorage.setItem('session', Session || '');
      return true;
    }
  } catch (error) {
    console.error('Error signing in: ', error);
    throw error;
  }
};

export const refreshCognitoAuth = async (refreshToken: string) => {
  const params = {
    AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    if (response) {
      return response['AuthenticationResult'];
    }
  } catch (error) {
    console.error('Error signing in: ', error);
    throw error;
  }
};

export const challengeAuth = async (username: string, password: string) => {
  const params = {
    ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
    ClientId: config.clientId,
    Session: sessionStorage.getItem("session") as any,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
  };
  try {
    const command = new RespondToAuthChallengeCommand(params);
    // @ts-ignore
    const { AccessToken } = await cognitoClient.send(command);
    if (AccessToken) {
      sessionStorage.setItem("idToken", '');
      sessionStorage.setItem("accessToken", '');
      sessionStorage.setItem("refreshToken", '');
      sessionStorage.setItem("session", '');
      return true;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const forgottenPassword = async (email: string) => {
  const params = {
    ClientId: config.clientId,
    Username: email
  };
  try {
    const command = new ForgotPasswordCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};

export const setNewCognitoPassword = async (email: string, password: string, code: string) => {
  const params = {
    ClientId: config.clientId,
    Username: email,
    Password: password,
    ConfirmationCode: code
  };
  try {
    const command = new ConfirmForgotPasswordCommand(params);
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error confirming sign up: ", error);
    throw error;
  }
};