import { ImperialMetric } from "../../../features/bookingSlice";
import { CustomerDetails, Preferences } from "../../../features/customerSlice";
import { Manifest } from "../../../features/manifestSlice";
import { LabelType } from "../../../utilities/HelperUtilities";
import { createManifestBookingRequest, CsvBooking, ManifestBookingRequest } from "./ManifestBookingRequestMapper";


export interface InputSenderDetails {
  name: string,
  organisation: string,
  addressLine1: string,
  addressLine2?: string,
  cityTown: string,
  countyStateProvince?: string,
  countryISO: string,
  postalCode?: string,
  telephone: string,
  residential: boolean
}
export interface  ManifestBookingRequestParams {
  email: string,
  tmffPartyId: string,
  customerPreferences: Preferences,
  readyDate: number,
  senderDetails: InputSenderDetails,
  labelType: LabelType | '',
  csvData: CsvBooking[],
  currencyCode: string,
  imperialOrMetric: ImperialMetric,
  isInternational: boolean,
  customer: CustomerDetails,
  service: string
}

export const createRequest = (customerDetails: CustomerDetails, customerPreferences: Preferences, manifest: Manifest) => {
  const manifestBookingRequestParams = {
    email: customerDetails.logonEmail,
    tmffPartyId: customerDetails.creditCheck.tmffPartyId,
    customerPreferences: customerPreferences,
    readyDate: manifest.readyDate,
    senderDetails: manifest.senderContactDetails,
    labelType: manifest.labelType.value,
    csvData: manifest.csvData,
    currencyCode: customerDetails.countryOfResidence.currencyCode,
    imperialOrMetric: manifest.imperialOrMetric,
    isInternational: manifest.isInternational,
    customer: customerDetails,
    service: manifest.isInternational ? manifest.internationalService.value : manifest.domesticService.value
  };

  return createManifestBookingRequest(manifestBookingRequestParams);
}
