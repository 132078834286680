import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Icon from '../../ui-components/icon/Icon';
import './Header.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { customerDetailsSelector } from '../../features/customerSlice';
import { bookingActions } from '../../features/bookingSlice';
import {appDataActions, appDataSelector} from '../../features/appDataSlice';
import { decodeJSONImage, generateImageUrl } from '../../utilities/HelperUtilities';
import { getRule } from "../../utilities/RulesEngineUtilities";

const Header: React.FC<{
  customRules: any;
}> = ({
  customRules
}) => {

  const location = useLocation().pathname;
  const customer = useAppSelector(customerDetailsSelector);
  const appData = useAppSelector(appDataSelector);

  const history = useHistory();

  const dispatch = useAppDispatch();

  const {width} = useWindowDimensions();

  const [mobileHeading, setMobileHeading] = useState<string>();
  const [logoCssClass, setLogoCssClass] = useState('header__logo');
  const [logo, setLogo] = useState<any>();

  useEffect(() => {
    window.pageYOffset === 0 ? setLogoCssClass('header__logo') : setLogoCssClass('header__logo header__logo--scroll');
    window.onscroll = () =>{
      window.pageYOffset === 0 ? setLogoCssClass('header__logo') : setLogoCssClass('header__logo header__logo--scroll');
    }
  }, []);

  useEffect(() => {
    let heading = '';
    switch(location) {
      case '/single-booking':
        heading = 'New Booking';
        break;
      case '/bulk-booking':
        heading = 'Bulk Booking';
        break;
      default:
        heading = 'Welcome'
    }

    setMobileHeading(heading)
  }, [location]);

  useEffect(() => {
    getAndSetLogo();
  }, [appData.previewMode, appData.temporaryLogo?.url, appData.wlLogoImage]);

  const getAndSetLogo = () => {
    if (appData.previewMode) setLogo(appData.temporaryLogo.url);
    else if (customer.creditCheck.whiteLabel && customer.creditCheck.whiteLabel.parentPartyId) {
      if (appData.wlLogoImage) {
        const image = decodeJSONImage(appData.wlLogoImage);
        setLogo(generateImageUrl(image));
      }
      else (setLogo('/images/express-fr8-logo.png'))
    }
    else if (customer.customerCountryCode === 'US') setLogo('/images/q2s-logo.png');
    else if (customer.customerCountryCode !== 'US') setLogo('/images/c2s-logo.png');
  }

  const onNewBookingClick = () => {
    dispatch(bookingActions.resetState());
    dispatch(appDataActions.resetStateWithConditions());
    if (location !== '/single-booking') history.push('/single-booking');
    else history.go(0)
  }

  const [bulkBookingClass, setBulkBookingClass] = useState<string>("");

  useEffect(() => {
    if (customRules) {
      if (getRule(appData.customRules, 'bulkBookingVisible')) {
        setBulkBookingClass('header__link');
        if (location === '/bulk-booking') {
          setBulkBookingClass('header__link--active')
        } else {
          setBulkBookingClass('header__link');
        }
      } else {
        setBulkBookingClass('header__link--disabled');
      }
    }

  }, [customRules, location]);

  if (width < 901) {
    return (
      <>
      {(location !== '/auth')
      && <div className="mobile-header">
        {logo && (
        <img
          src={logo}
          alt="Logo"
          className="mobile-header__logo"
          onClick={() => history.push('/single-booking')}
        />
        )}
        <p className="mobile-header__heading">{mobileHeading}</p>
      </div>}
    </>
    )
  }

  return (
    <>
      {(location !== '/auth')
      && <div className="header">
        <div className="header__angle-block"></div>
        {logo && (
          <img
            src={logo}
            alt="Logo"
            className={logoCssClass}
            onClick={() => history.push('/single-booking')}
          />
        )}
        <div className="header__link-container">
          <div className="header__link-container__main-links">
            <p
              className={location.includes('single') ? 'header__link--active' : 'header__link'}
              onClick={onNewBookingClick}
            >
              New Booking
            </p>

            <p
              className={bulkBookingClass}
              onClick={() => history.push('/bulk-booking')
              }
            >
              Bulk Booking
            </p>

            <p
              className={location === '/tracking' ? 'header__link--active' : 'header__link'}
              onClick={() => history.push('/tracking')}
            >
              Tracking
            </p>
          </div>
          <div
            className="header__account-menu"
            onClick={() => history.push('/account')}
          >
            <Icon
              name="User"
              color="var(--textLight)"
              style={{opacity: '.9', marginRight: '4px'}}/>
            <p className="header__link">Account</p>
          </div>
        </div>
      </div>}
    </>
  )
};

export default Header;