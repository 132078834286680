import { ApiConfig } from "../features/appDataSlice";
import {
  Booking,
  bookingActions,
  ImperialMetric,
  QuoteObj,
  ShipmentType,
  TermsOfSale
} from "../features/bookingSlice";
import { customerActions, CustomerDetails } from "../features/customerSlice";
import {getAddresses, getDocumentCall} from "./APIUtilities";
import dayjs from "dayjs";
import { retrieveImage } from "./ContentRetrieverUtilities";
import { getTradeRoute, TradeRoute } from "./RulesEngineUtilities";

export function trimTelephone(value: string) {
  return value.replace(/[^0-9]/g, '');
}

export function selectTitleFormatter(value: string | number) {
  switch (value) {
    case 'THERMAL':
      return 'Thermal'
    case 'PDF_A4':
      return 'PDF A4'
    case 'PDF_4X6':
      return 'PDF 4x6'
    case 'TYPE1':
      return 'Type 1'
    case 'FRONT':
      return 'Front'
    case 'NONE':
      return 'None'
    case 'REAR':
      return 'Rear'
    case 'SIDE':
      return 'Side'
    case 'SOLD':
      return 'Commercial'
    case 'GIFT':
      return 'Gift'
    case 'SAMPLE':
      return 'Sample'
    case 'REPAIR_AND_RETURN':
      return 'Repair and return'
    case 'PERSONAL_EFFECTS':
      return 'Personal effects'
    case 'NOT_SOLD':
      return 'Not sold'
    default:
      return ''
  }
}

export function dimensionUnitFormatter(unitType: ImperialMetric) {
  switch (unitType) {
    case ImperialMetric.IMPERIAL:
      return 'IN'
    case ImperialMetric.METRIC:
      return 'CM'
  }
}

export function weightUnitFormatter(unitType: ImperialMetric) {
  switch (unitType) {
    case ImperialMetric.IMPERIAL:
      return 'LB'
    case ImperialMetric.METRIC:
      return 'KG'
  }
}

export function formatShipmentTypeForDisplay(type: ShipmentType) {
  switch (type) {
    case ShipmentType.NON_DOCS:
      return 'Non Docs';
    case ShipmentType.DOCS:
      return 'Docs';
    case ShipmentType.ENVELOPE:
      return 'Envelope';
  }
}

export enum ServiceType {
  DROP_IN = "DROP_IN",
  COLLECTED = "COLLECTED"
}

export enum FedexProductCode {
  FEDEX_2_DAY = "FEDEX_2_DAY",
  FEDEX_GROUND = "FEDEX_GROUND",
  STANDARD_OVERNIGHT = "STANDARD_OVERNIGHT",
  PRIORITY_OVERNIGHT = "PRIORITY_OVERNIGHT",
  FEDEX_2_DAY_AM = "FEDEX_2_DAY_AM",
  FEDEX_INTERNATIONAL_PRIORITY = "FEDEX_INTERNATIONAL_PRIORITY"
}

export function uppercaseAmPm(text: string) {
  const split = text.split(' ');
  for (const word of split) {
    if (word === 'Am' || word === 'Pm') {
      split[split.indexOf(word)] = word.toUpperCase();
    }
  }
  return split.join(' ');
}

export function snakeCaseConvertor(text: string) {
  const lowerCaseString = text.toLowerCase().replaceAll(/_/g, ' ');
  const arr = lowerCaseString.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(" ");
}

export enum LabelType {
  PDF_A4 = 'PDF_A4',
  PDF_4X6 = 'PDF_4X6',
  THERMAL = 'THERMAL'
}

export enum LabelSize {
  US_LETTER ='US_LETTER',
  A4 = 'A4',
  STOCK_4X6 ='STOCK_4X6'
}

export const generateLabel = (labelType: LabelType | string, customer: CustomerDetails) => {
  const isUS = customer.countryOfResidence.value === 'US';

  let label = {
    type: "THERMAL",
    size: LabelSize.STOCK_4X6
  }

  if (labelType === LabelType.PDF_A4) {
    label = {
      type: "PDF",
      size: isUS ? LabelSize.US_LETTER : LabelSize.A4
    };
  }
  if (labelType === LabelType.PDF_4X6) {
    label = {
      type: "PDF",
      size: LabelSize.STOCK_4X6
    }
  }

  return label;
}

export const getS3ImageUrl = async(customer: CustomerDetails, imageId: string) => {
  const result = await retrieveImage(customer, imageId);
  return generateImageUrl(result);
}

export const generateImageUrl = (file: any) => {
  const blob = new Blob(
    [file.data],
    { type: file.type }
  )
  return window.URL.createObjectURL(blob);
}

export const decodeJSONImage = (jsonImage: string) => {
  const converted = JSON.parse(jsonImage);
  const data = new Uint8Array(JSON.parse(converted.data)).buffer;

  return {
    ...converted,
    data
  }
}

export const encodeJSONImage = (image: any) => {
  const logoBuffer = JSON.stringify(Array.from(new Uint8Array(image.data)))

  return JSON.stringify({
    ...image,
    data: logoBuffer
  })
}

export const generateBlobAndDownload = (file: any, fileName: string) => {
  const url = generateImageUrl(file);
  const link = document.createElement('a');

  if (file.type === 'application/zip') {
    link.setAttribute('download', `${fileName}s.zip`);
  } else if (file.type === 'application/pdf') {
    link.setAttribute('download', `${fileName}.pdf`);
  } else if (file.type === 'text/plain') {
    link.setAttribute('download', `${fileName}.zpl`)
  }

  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const generateStreetLinesArray = (line1: string, line2: string, line3?: any) => {
  const addressLineArray = [trimIfString(line1)];

  if (line2) addressLineArray.push(trimIfString(line2));
  if (line3) addressLineArray.push(trimIfString(line3));

  return addressLineArray;
}

export const getCarrierName = (quoteId: string) => {
  return quoteId.split('-')[0];
}

export const formatCarrierName = (name: string) => {
  switch (name) {
    case 'dhl':
      return 'DHL';
    case 'fedex':
      return 'FedEx';
    case 'toll':
      return 'Team Global Express';
    case 'ups':
      return 'UPS';
    default:
      return 'Carrier'
  }
}

export const getCarrierImage = (quoteId: string) => {
  const carrier = getCarrierName(quoteId);
  return `images/${carrier}.png`;
}

export const getPostalCodeRegex = (countryInfo: any) => {
  return countryInfo.postcodeFormat === 'Country not postcode-format enabled.'
    ? ''
    : countryInfo.postcodeFormat;
}

export enum OptionalCharge {
  INSURANCE = 'INSURANCE',
  DELIVERED_DUTY_PAID = 'DELIVERED_DUTY_PAID'
}

export const getOptionalChargeCost = (quote: QuoteObj, type: OptionalCharge) => {
  let cost = 0;
  if (quote.charges.optional) {
    for (const charge of quote.charges.optional) {
      if (charge.type === type) {
        cost = charge.value;
      }
    }
  }
  return cost;
}

export const checkFedexFreeDDP = (quote: QuoteObj, customer: CustomerDetails) => {
  let free = false;
  if (
    quote.charges.optional
    && getCarrierName(quote.quoteId) === 'fedex'
    && customer.countryOfResidence.value === 'GB'
  ) {
    for (const charge of quote.charges.optional) {
      if (charge.type === OptionalCharge.DELIVERED_DUTY_PAID) {
        free = true;
      }
    }
  }
  return free;
}

export const calculateBookingTotalCost = (selectedQuote: QuoteObj, booking: Booking, customer: CustomerDetails) => {
  const initialCost = selectedQuote.totalCost.value;
  const insure = booking.insure;
  const ddp = booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP;
  const ednValue = booking.shipperDetails.generateECS && selectedQuote.charges.optional?.find(el => el.type === 'EDN')?.value;
  const ccn = booking.shipperDetails.generateECS && booking.shipperDetails.exportComplianceStatement.ccn;
  const vat = getTradeRoute(customer, booking) === TradeRoute.DOMESTIC && booking.origin.value === 'AU' && selectedQuote.vat.value ? selectedQuote.vat.value : 0;

  return (
    initialCost
    + (insure ? getOptionalChargeCost(selectedQuote, OptionalCharge.INSURANCE) : 0)
    + (ddp ? getOptionalChargeCost(selectedQuote, OptionalCharge.DELIVERED_DUTY_PAID) : 0)
    + (ednValue ? ednValue : 0)
    + (ccn ? 17.94 : 0)
    + vat
  )
}

export const getCommodityUrl = (countryCode: string) => {
  switch (countryCode) {
    case 'US':
      return 'https://uscensus.prod.3ceonline.com/';
    case 'AU':
      return 'https://export.business.gov.au/find-export-markets/market-search-tool';
    case 'NZ':
      return 'https://www.tariff-finder.govt.nz/';
    case 'GB':
      return 'https://www.gov.uk/trade-tariff';
    case 'NL':
      return 'https://www.tariffnumber.com/';
    default:
      return 'https://uscensus.prod.3ceonline.com/';
  }
}

export const formatCounties = (counties: any) => {
  return counties.map((county: any) => {
    return {
      title: county.name,
      value: county.code
    }
  })
}

export const createCountryObject = (countries: any, countryCode: string) => {
  return countries.find((country: any) => {
    return country.value === countryCode;
  }) as any;
}

export const removePeriodsFromString = (string: string) => {
  return string.replace('.', '');
}

export const removeUnderscoresFromString = (string: string) => {
  return string.replace(/_/g, '');
}

const removeAddressesWithInvalidCountryCodes = (mappedAddresses: any, countries: any) => {
  let allCountryCodes: any = [];
  for (const country of countries) {
    allCountryCodes.push(country.value)
  }
  return mappedAddresses.filter((address: any) => {
    return allCountryCodes.includes(address.userDetails.address.countryCode);
  })
}

export const getAndSortAddresses = async(customer: CustomerDetails, dispatch: any, countries: any, apiConfig: ApiConfig) => {
  const branchId = customer.creditCheck.branchId;
  const addresses = await getAddresses(branchId, apiConfig);

  if (addresses?.length > 0) {
    let mappedAddresses = addresses.map((address: any) => {
      return address.request
    })
    mappedAddresses = removeAddressesWithInvalidCountryCodes(mappedAddresses, countries);
    dispatch(customerActions.setSavedAddresses(mappedAddresses));
  } else {
    dispatch(customerActions.setSavedAddresses([]))
  }
}

export const deepEqualObjects = (x: any, y: any):any => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqualObjects(x[key], y[key]))
  ) : (x === y);
}

export const setDefaultEmailAddress = (customer: CustomerDetails, dispatch: any, activeTemplate: any) => {
  if (
    activeTemplate
    && activeTemplate.data.emailPreference
  ) {
    dispatch(bookingActions.updatePreferencesDetail({
      field: 'email',
      value: activeTemplate.data.emailPreference
    }))
  } else {
    dispatch(bookingActions.updatePreferencesDetail({
      field: 'email',
      value: customer.logonEmail
    }))
  }
}

export const camelCaseToSentence = (fieldName: string) => {
  const spaced = fieldName.replace(/([A-Z])/g,' $1');
  return spaced.charAt(0).toUpperCase()+spaced.slice(1);
}

export const collectionOrDropInCityTown = (booking: Booking) => {
  if (booking.selectedQuote.serviceType === "DROP_IN") return booking.dropInDetails.cityTown
  else return booking.collectionDetails.cityTown
}

export const getUKDeclarationNotesValue = (booking: Booking) => {
  return `The exporter of the products covered by this document declares that, except where otherwise
    clearly indicated, these products are of UK preferential origin. ${booking.shipperDetails.companyName},
    ${collectionOrDropInCityTown(booking)}, ${dayjs().format('DD/MM/YYYY')}.`
}

export const roundUpToNearestPointFive = (value: number) => {
  let array = value.toString().split('.');
  let decimal = +array[1];
  if (decimal > 5) value = Math.ceil(value);
  if (decimal < 5) {
    array.splice(1, 1, '5')
    value = +array.join('.');
  }
  return value;
}

export const trimIfString = (value: any) => {
    if (typeof value === 'string') return value.trim();
    else return value;
}

export const downloadDocuments = async(customer: CustomerDetails, amiRef: string) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customer.auth.accessToken}`
    },
    responseType: 'arraybuffer'
  };
  let document;

  if (amiRef) {
    document = await getDocumentCall(config, customer, amiRef);
    if (!document.documentReady) {
      return null
    }
    if (document.data && document.documentReady) {
      generateBlobAndDownload(document, 'invoice');
    }
  }
  return document;
}

export const removeUnwantedFields = (state: any) => {
  delete state.readyDate;
  delete state.selectedQuote;
  delete state.uploadedInvoice.documentContent;
}

export const scrollToElement = (section: string) => {
  const element = document.getElementById( `${section}` );
  element && element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
}

export const getUpsUrl = (booking: Booking) => {
  const countryCode = booking.origin.value;
  switch(countryCode) {
    case 'US':
      return 'https://www.ups.com/dropoff?loc=en_US';
    case 'GB':
      return 'https://www.ups.com/dropoff?loc=en_GB'
    default:
      return ''
  }
}

export const preventSpaceBarScrolling = (event: any) => {
  if (event. code === 'Space') event. preventDefault();
}

export const getECSData = (booking : Booking) => {
  switch (booking.origin.value) {
    case "AU": return {
      type: "EDN:",
      amount: "22.50"
    };
    case "NZ": return {
      type: "CCN:",
      amount: "17.94"
    };
  }
};

export const calculateBasePrice = (customer: CustomerDetails, quote: QuoteObj) => {
  if (customer.countryOfResidence.value === 'GB') return quote.rate.value - quote.discount.amount.value;
  else return quote.rate.value;
}