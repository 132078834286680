import React from 'react';
import './CustomerPageDetails.scss';
import { AMIFormElement, AMIInput } from '../../../../../../ui-components/UiComponents';

const CustomerPageDetails: React.FC<{
  entity: any;
}> = ({
  entity
}) => {

  const address = entity.address;

  return (
    <div className="customer-page-details">
      <h4>Contact Details</h4>

      <form>
        <AMIFormElement
          label="Company Name"
        >
          <AMIInput
            name="companyName"
            value={address.organisation}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Address Line 1"
        >
          <AMIInput
            name="addressLine1"
            value={address.streetLines[0]}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Address Line 2"
        >
          <AMIInput
            name="addressLine2"
            value={address.streetLines[1]}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Address Line 3"
        >
          <AMIInput
            name="addressLine3"
            value={address.streetLines[2]}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="City/Town"
        >
          <AMIInput
            name="cityTown"
            value={address.city}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="County/State/Province"
        >
          <AMIInput
            name="countyStateProvince"
            value={address.stateOrCounty}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Country Code"
        >
          <AMIInput
            name="countryCode"
            value={address.countryCode}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

        <AMIFormElement
          label="Postal Code"
        >
          <AMIInput
            name="postalCode"
            value={address.postalCode}
            size="large"
            disabled
            onChange={() => null}
          />
        </AMIFormElement>

      </form>
    </div>
  )
}

export default CustomerPageDetails;