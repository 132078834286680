import React, { useEffect, useState } from 'react';
import CustomerPage from './customer-page/CustomerPage';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { useAppSelector } from '../../../../app/hooks';
import './WhiteLabelCustomersPage.scss';
import { AMIButton, AMIFormElement, AMIInput, AMIModal, AMISpinner } from '../../../../ui-components/UiComponents';
import { generateStreetLinesArray } from '../../../../utilities/HelperUtilities';
import { getWlEntities, postWlEntity } from '../../../../utilities/APIUtilities';
import { appDataSelector } from '../../../../features/appDataSlice';

const BLANK_CUSTOMER = {
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  cityTown: '',
  countyStateProvince: '',
  countryCode: '',
  postalCode: ''
};

const WhiteLabelCustomersPage = () => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [activeCustomer, setActiveCustomer] = useState<any>();
  const [showAddNewCustomerModal, setShowAddNewCustomerModal] = useState(false);
  const [newCustomer, setNewCustomer] = useState(BLANK_CUSTOMER);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    getAndSetCustomers()
  }, [])

  const getAndSetCustomers = async() => {
    setIsFetching(true);
    const response = await getWlEntities(apiConfig) as any;
    if (response?.data) setCustomers(response.data);
    setIsFetching(false);
  }

  const onFieldChange = (field: string, value: string) => {
    setNewCustomer(prevState => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  const onSaveClick = async() => {
    setIsSaving(true);
    const body = {
      address: {
        city: newCustomer.cityTown,
        countryCode: newCustomer.countryCode,
        organisation: newCustomer.companyName,
        postalCode: newCustomer.postalCode,
        stateOrCounty: newCustomer.countyStateProvince ? newCustomer.countyStateProvince : null,
        streetLines: generateStreetLinesArray(newCustomer.addressLine1, newCustomer.addressLine2, newCustomer.addressLine3)
      }
    }
    const response = await postWlEntity(body, apiConfig);
    if (response) {
      setShowAddNewCustomerModal(false);
      setNewCustomer(BLANK_CUSTOMER);
    } else {
      window.alert("An error occurred, please try again.")
    }
    setIsSaving(false);
  }

  return (
    <>
      <div className="customers-page">
        <div className="customers-page__top-line">
          <h2>Customers</h2>
          <p onClick={() => setShowAddNewCustomerModal(true)}>+ Add New Customer</p>
        </div>

        {isFetching && (
          <div className="wl-bookings__spinner">
            <AMISpinner />
            <p>Fetching customers...</p>
          </div>
        )}

        {customers.length > 0 && customers.map((el: any) => {
          return (
            <div onClick={() => setActiveCustomer(el)} key={el.id}>
              <CustomerPage
                entity={el}
                active={el.id === activeCustomer?.id}
              />
            </div>
          )
        })}
      </div>

      {showAddNewCustomerModal && (
        <AMIModal
          title="Add New Customer"
          width="90vw"
          buttons={[
            <AMIButton
              size="large"
              key={Math.random() * 100}
              onClick={() => setShowAddNewCustomerModal(false)}
            >Close</AMIButton>,
            <AMIButton
              size="large"
              variant="primary"
              isSpinning={isSaving}
              key={Math.random() * 100}
              onClick={onSaveClick}
            >Save</AMIButton>
          ]}
          close={() => setShowAddNewCustomerModal(false)}
        >
          <form className="white-label-add-new-customer-modal">
            <AMIFormElement
              label="Company Name"
            >
              <AMIInput
                name="companyName"
                value={newCustomer.companyName}
                size="large"
                onChange={(event) => onFieldChange("companyName", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 1"
            >
              <AMIInput
                name="addressLine1"
                value={newCustomer.addressLine1}
                size="large"
                onChange={(event) => onFieldChange("addressLine1", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 2"
            >
              <AMIInput
                name="addressLine2"
                value={newCustomer.addressLine2}
                size="large"
                onChange={(event) => onFieldChange("addressLine2", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 3"
            >
              <AMIInput
                name="addressLine3"
                value={newCustomer.addressLine3}
                size="large"
                onChange={(event) => onFieldChange("addressLine3", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="City/Town"
            >
              <AMIInput
                name="cityTown"
                value={newCustomer.cityTown}
                size="large"
                onChange={(event) => onFieldChange("cityTown", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="County/State/Province"
            >
              <AMIInput
                name="countyStateProvince"
                value={newCustomer.countyStateProvince}
                size="large"
                onChange={(event) => onFieldChange("countyStateProvince", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Country Code"
            >
              <AMIInput
                name="countryCode"
                value={newCustomer.countryCode}
                size="large"
                onChange={(event) => onFieldChange("countryCode", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Postal Code"
            >
              <AMIInput
                name="postalCode"
                value={newCustomer.postalCode}
                size="large"
                onChange={(event) => onFieldChange("postalCode", event.target.value)}
              />
            </AMIFormElement>

          </form>
        </AMIModal>
      )}
    </>
  )
}

export default WhiteLabelCustomersPage;