import React, {useEffect, useState} from 'react';
import { Icon } from '../../ui-components/UiComponents';
import './FaqPage.scss';
import {FAQ} from './utilities';
import {retrieveContent} from "../../utilities/ContentRetrieverUtilities";
import {useAppSelector} from "../../app/hooks";
import {customerDetailsSelector} from "../../features/customerSlice";
import AMISpinner from "../../ui-components/spinner/Spinner";
import { appDataSelector } from '../../features/appDataSlice';

const FaqPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const { apiConfig } = useAppSelector(appDataSelector);

  const [openFaqIndex, setOpenFaqIndex] = useState(0);

  const [faqContent, setFaqContent] = useState<any>([]);

  const [isSpinning, setIsSpinning] = useState<boolean>(true);

  useEffect(() => {
    retrieveContent('faqs', null, customer, apiConfig )
      .then((result: any) => {
        setFaqContent(result);
        setIsSpinning(false);
      })
      .catch(error => {
        console.error(error);
        setIsSpinning(false);
      });
  }, [])

  return (
    <div className="faq-page vertical-card">
      <h1 className="card-header">Frequently Asked Questions</h1>
      <div className="horizontal-card">
        {isSpinning && <AMISpinner className="faq-page__spinner"/>}
        {!isSpinning && faqContent && faqContent.map((faq: FAQ, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    openFaqIndex === index
                      ? 'faq-page__faq faq-page__faq--open'
                      : 'faq-page__faq faq-page__faq--closed'
                  }
                  onClick={() => setOpenFaqIndex(index)}
                >
                  <Icon
                    name={
                      openFaqIndex === index
                        ? "ChevronUp"
                        : "ChevronDown"
                    }
                    style={{
                      position: 'absolute',
                      right: '24px',
                      top: '16px'
                    }}
                    color={
                      openFaqIndex === index
                        ? 'var(--primary)'
                        : 'inherit'
                    }
                  />
                  <h3>{faq.title}</h3>
                  <div className="faq-content" dangerouslySetInnerHTML={{__html: faq.content}}></div>
                </div>
              )
            })
          }

          {!isSpinning && !faqContent && (
            <p>Sorry, we could not load the FAQs at this time. Please refresh the page or try again later. If you require immediate assistance then please contact customer services.</p>
          )}
      </div>
    </div>
  )
}

export default FaqPage;