import React, { useEffect, useState } from 'react';
import AMISwitch from '../../../../../../../ui-components/switch/Switch';
import { AMIButton, AMIFormElement, AMIInput, AMIModal, Icon } from '../../../../../../../ui-components/UiComponents';
import { generateStreetLinesArray } from '../../../../../../../utilities/HelperUtilities';
import { useAppSelector } from '../../../../../../../app/hooks';
import { appDataSelector } from '../../../../../../../features/appDataSlice';
import { updateWlUser } from '../../../../../../../utilities/APIUtilities';

const UserLoginLine: React.FC<{login: any; getAndSetLogins: any}> = ({login, getAndSetLogins}) => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [updatedLogin, setUpdatedLogin] = useState({
    name: '',
    email: '',
    telephone: '',
    countryCode: '',
    postalCode: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateOrCounty: ''
  })

  useEffect(() => {
    setUpdatedLogin((prevState) => {
      return {
        ...prevState,
        name: login.contactDetails.name,
        email: login.email,
        telephone: login.contactDetails.telephone,
        countryCode: login.address.countryCode,
        postalCode: login.address.postalCode,
        address1: login.address.streetLines[0],
        address2: login.address.streetLines[1],
        address3: login.address.streetLines[2],
        city: login.address.city,
        stateOrCounty: login.address.stateOrCounty
      }
    })
  }, [])

  const onSaveClick = async() => {
    setIsSaving(true);
    const body = {
      address: {
          countryCode: updatedLogin.countryCode,
          postalCode: updatedLogin.postalCode,
          streetLines: generateStreetLinesArray(updatedLogin.address1, updatedLogin.address2, updatedLogin.address3),
          city: updatedLogin.city,
          stateOrCounty: updatedLogin.stateOrCounty ? updatedLogin.stateOrCounty : null,
      },
      contactDetails: {
          name: updatedLogin.name,
          telephone: updatedLogin.telephone
      }
    }

    const response = await updateWlUser(body, apiConfig, login.id);
    if (!response) window.alert("An error occurred. Please try again or contact customer service.");
    setIsSaving(false);
    if (response) {
      setShowModal(false);
      getAndSetLogins();
    }
  }

  const onFieldChange = (field: string, value: string) => {
    setUpdatedLogin((prevState) => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  return (
    <>
      <tr>
        <td>{login.contactDetails.name}</td>
        <td>{login.email}</td>
        {/* <td>
          REQUIRED FOR DAY 2 WL WHEN USERS ARE ENABLE/DISABLED
          <AMISwitch
            titles={[]}
            position={login.enabled}
            onToggle={() => null}
          />
        </td> */}
        <td>
          <Icon
            name="Edit"
            style={{cursor: "pointer"}}
            onClick={() => setShowModal(true)}
          />
        </td>
      </tr>

      {showModal && (
        <AMIModal
          title={`Edit ${login.email}`}
          width="90vw"
          buttons={[
            <AMIButton
              size="large"
              key={Math.random() * 100}
              disabled={isSaving}
              onClick={() => setShowModal(false)}
            >Close</AMIButton>,
            <AMIButton
              size="large"
              variant="primary"
              isSpinning={isSaving}
              key={Math.random() * 100}
              onClick={onSaveClick}
            >Save</AMIButton>
          ]}
          close={() => setShowModal(false)}
          >
          <form className="customer-logins-add-user-modal">
            <AMIFormElement
              label="Name"
            >
              <AMIInput
                name="name"
                value={updatedLogin.name}
                size="large"
                onChange={(event) => onFieldChange("name", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Email"
            >
              <AMIInput
                name="email"
                value={login.email}
                size="large"
                disabled
                onChange={() => null}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Phone Number"
            >
              <AMIInput
                name="phone"
                value={updatedLogin.telephone}
                size="large"
                onChange={(event) => onFieldChange("telephone", event.target.value)}
              />
            </AMIFormElement>

            <div />

            <AMIFormElement
              label="Address Line 1"
            >
              <AMIInput
                name="address1"
                value={updatedLogin.address1}
                size="large"
                onChange={(event) => onFieldChange("address1", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 2"
            >
              <AMIInput
                name="address2"
                value={updatedLogin.address2}
                size="large"
                onChange={(event) => onFieldChange("address2", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 3"
            >
              <AMIInput
                name="address2"
                value={updatedLogin.address3}
                size="large"
                onChange={(event) => onFieldChange("address3", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="City/Town"
            >
              <AMIInput
                name="cityTown"
                value={updatedLogin.city}
                size="large"
                onChange={(event) => onFieldChange("city", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="State/County"
            >
              <AMIInput
                name="stateCounty"
                value={updatedLogin.stateOrCounty}
                size="large"
                onChange={(event) => onFieldChange("stateOrCounty", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Country Code"
            >
              <AMIInput
                name="countryCode"
                value={updatedLogin.countryCode}
                size="large"
                onChange={(event) => onFieldChange("countryCode", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Postal Code"
            >
              <AMIInput
                name="postalCode"
                value={updatedLogin.postalCode}
                size="large"
                onChange={(event) => onFieldChange("postalCode", event.target.value)}
              />
            </AMIFormElement>

          </form>
        </AMIModal>
      )}
    </>
  )
}

export default UserLoginLine;