import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIFormElement, AMIInput, AMISelect } from '../../../../ui-components/UiComponents';
import { appDataSelector } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector } from '../../../../features/bookingSlice';
import { LabelType, selectTitleFormatter } from '../../../../utilities/HelperUtilities';

const DetailsPagePreferences: React.FC<{
  errHandler: any;
}> = ({
  errHandler
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const showErrors = appData.showDetailsPageErrors;

  const changePreferencesDetail = (field: string, event: any) => {
    dispatch(bookingActions.updatePreferencesDetail({
      field,
      value: event.value
    }))
  }

  return (
    <section
      className="preferences-section"
      id="preference-details"
    >
      <p className="details-container__title">Preferences</p>

      <AMIFormElement
          label="Email Confirmation"
          errorMessage={
            showErrors
            && !errHandler.preferences.email.criteria
            ? errHandler.preferences.email.message
            : ''
          }
        >
          <AMIInput
            name="preferenceEmail"
            placeholder="Required"
            size="large"
            type="email"
            value={booking.preferences.email}
            onChange={(event) => changePreferencesDetail('email', event.target)}
          />
      </AMIFormElement>

      <AMIFormElement
        label="Label Type"
        errorMessage={
          showErrors
          && !errHandler.preferences.labelType.criteria
            ? errHandler.preferences.labelType.message
            : ''
        }
      >
        <AMISelect
          name="preferencesLabelType"
          placeholder="Required"
          size="large"
          defaultValue={{
            title: selectTitleFormatter(booking.preferences.labelType),
            value: booking.preferences.labelType
          }}
          options={[
            {title: 'PDF A4', value: LabelType.PDF_A4},
            {title: 'PDF 4x6', value: LabelType.PDF_4X6},
            {title: 'Thermal', value: LabelType.THERMAL}
          ]}
          hideClearButton
          onChange={changePreferencesDetail.bind(null, 'labelType')}
        />
      </AMIFormElement>

    </section>
  )
}

export default DetailsPagePreferences;