import React from 'react';
import { Banner } from '../../../../utilities/ContentRetrieverUtilities';
import { AMIAlert } from '../../../../ui-components/UiComponents';
import { CustomerDetails, customerDetailsSelector } from '../../../../features/customerSlice';
import { useAppSelector } from '../../../../app/hooks';
import { appDataSelector } from '../../../../features/appDataSlice';

const Banners: React.FC<{banners: Banner[] | undefined, customer: CustomerDetails}> = ({banners, customer}) => {

  const { isWl } = useAppSelector(appDataSelector);
  const { bookingAllowed } = useAppSelector(customerDetailsSelector);

  return (
    <>
      {!customer.creditCheck.credit && !isWl && (
        <AMIAlert
          variant="warning"
          style={{marginBottom: "24px"}}
        >
          <p><strong>You are out of credit, please contact Credit Control.</strong></p>
          <p>If you have recently made a payment, please wait for your credit status to update. If you are still unable to place a booking, please get in contact with us.</p>
        </AMIAlert>
      )}

      {isWl && !bookingAllowed && (
        <AMIAlert
          variant="warning"
          style={{marginBottom: "24px"}}
        >
          <p><strong>Booking Suspended</strong></p>
          <p>Please contact customer services.</p>
        </AMIAlert>
      )}

      {banners && banners.length > 0 && banners.map((banner: Banner, index: number) => {
        return (
          <AMIAlert
            variant={banner.config.announcementType}
            style={{marginBottom: "24px"}}
            key={index}
          >
            <p><strong>{banner.title}</strong></p>
            <div className="faq-content" dangerouslySetInnerHTML={{__html: banner.content}}></div>
          </AMIAlert>
        )
      })}
    </>
  )
}

export default Banners;