import React, { useState } from 'react';
import './CustomerPage.scss';
import CustomerPageDetails from './customer-page-details/CustomerPageDetails';
import CustomerPageMarkups from './customer-page-markups/CustomerPageMarkups';
import CustomerPageLogins from './customer-page-logins/CustomerPageLogins';

const CustomerPage: React.FC<{
  entity: any;
  active: boolean;
}> = ({
  entity,
  active
}) => {

  const address = entity.address;

  const [activeSection, setActiveSection] = useState<'DETAILS' | 'MARKUPS' | 'LOGINS'>('DETAILS');

  if (!active) {
    return (
      <div className="customer-page customer-page--closed">
        <h3>{address.organisation}</h3>
      </div>
    )
  }

  return (
    <div className="customer-page">
      <h3>{entity.address.organisation}</h3>

      <hr />

      <div className="customer-page__nav">
        <div
          className={
            activeSection === "DETAILS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('DETAILS')}
        >Details</div>
        <div
          className={
            activeSection === "MARKUPS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('MARKUPS')}
        >Markups</div>
        <div
          className={
            activeSection === "LOGINS" ? "customer-page__nav__item customer-page__nav__item--active" : "customer-page__nav__item"
          }
          onClick={() => setActiveSection('LOGINS')}
        >Logins</div>
      </div>

      {activeSection === "DETAILS" && <CustomerPageDetails entity={entity} />}
      {activeSection === "MARKUPS" && <CustomerPageMarkups entity={entity} />}
      {activeSection === "LOGINS" && <CustomerPageLogins entity={entity} />}
    </div>
  )
}

export default CustomerPage;