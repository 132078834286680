export enum MarkupType {
  PERCENTAGE = 'percentage',
  FLAT_AMOUNT = 'flatAmount'
}

export enum Distribution {
  GENERAL = 'general',
  WEIGHT_BREAK = 'weightBreak'
}

export enum PerShipmentType {
  DOCS = 'docs',
  NON_DOCS = 'nonDocs',
  BOTH = 'both'
}

export const blankMarkupState = {
  docs: {
    flatAmount: {
      general: {
        markup: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    },
    percentage: {
      general: {
        markup: '',
        min: '',
        max: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    }
  },
  nonDocs: {
    flatAmount: {
      general: {
        markup: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    },
    percentage: {
      general: {
        markup: '',
        min: '',
        max: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    }
  },
  both: {
    flatAmount: {
      general: {
        markup: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    },
    percentage: {
      general: {
        markup: '',
        min: '',
        max: ''
      },
      weightBreak: {
        min: '',
        max: ''
      }
    }
  }
}

export const checkForErrors = () => {
  const errors = document.getElementsByClassName("ami-form-element--error");
  return errors.length > 0;
}

export const convertWeightBreaks = (weights: any) => {
  const sortedWeights = weights.sort((a: any, b: any) => {
    return +a.value - +b.value
  })

  const weightBreaks = sortedWeights.map((weight: any, index: number) => {
    const fromValue = index >= 1 ? sortedWeights[index -1].value : '0';
    return {
      id: Math.random().toString(16).slice(2),
      markup: weight.markup,
      upTo: weight.value,
      from: fromValue
    }
  })

  return weightBreaks;
}

const convertWeightBreaksForBackend = (weightBreaks: any[], weightUnit: string) => {
  return weightBreaks.map((weightBreak: any, index: number) => {
    const isLast = index === weightBreaks.length - 1;
    const lastValue = weightUnit === 'lb' ? '21999' : '9999';

    return {
      markup: weightBreak.markup,
      value: isLast ? lastValue : weightBreak.upTo
    }
  })
}

export const createMarkupObject = (selections: any, values: any, entityId: string, weightUnit: string, weightBreaks: any, isEnabled: boolean) => {
  const markup: any = {
    type: entityId,
    weight: selections.distribution.value === Distribution.WEIGHT_BREAK,
    shipment: selections.perShipmentType.value === 'YES',
    percentage: selections.markupType.value === MarkupType.PERCENTAGE,
    enabled: isEnabled
  }

  if (!markup.shipment) {
    if (markup.percentage) {
      markup.min = values.both.percentage.general.min,
      markup.max = values.both.percentage.general.max,
      markup.markup = values.both.percentage.general.markup
    }
    if (!markup.percentage && !markup.weight) {
      markup.markup = values.both.flatAmount.general.markup
    }
    if (markup.weight) {
      delete markup.markup;
      markup.unit = weightUnit;
      markup.weights = convertWeightBreaksForBackend(weightBreaks.both, weightUnit);

      if (markup.percentage) {
        markup.min = values.both.percentage.weightBreak.min;
        markup.max = values.both.percentage.weightBreak.max;
      }
    }
  }

  if (markup.shipment) {
    markup.shipments = {
      docs: {},
      nonDocs: {}
    }
    if (markup.percentage) {
      markup.shipments = {
        docs: {...values.docs.percentage.general},
        nonDocs: {...values.nonDocs.percentage.general}
      }
    }
    if (!markup.percentage && !markup.weight) {
      markup.shipments = {
        docs: {markup: values.docs.flatAmount.general.markup},
        nonDocs: {markup: values.nonDocs.flatAmount.general.markup}
      }
    }
    if (markup.weight) {
      markup.unit = weightUnit;
      markup.shipments.docs.weights = convertWeightBreaksForBackend(weightBreaks.docs, weightUnit);
      markup.shipments.nonDocs.weights = convertWeightBreaksForBackend(weightBreaks.nonDocs, weightUnit);

      if (markup.percentage) {
        markup.shipments.docs.min = values.docs.percentage.weightBreak.min;
        markup.shipments.docs.max = values.docs.percentage.weightBreak.max;
        delete markup.shipments.docs.markup;
        markup.shipments.nonDocs.min = values.nonDocs.percentage.weightBreak.min;
        markup.shipments.nonDocs.max = values.nonDocs.percentage.weightBreak.max;
        delete markup.shipments.nonDocs.markup;
      }
    }
  }

  return markup;
}

export const defaultWeightBreaks = {
  both: [
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    }
  ],
  docs: [
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    }
  ],
  nonDocs: [
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    },
    {
      id: Math.random().toString(16).slice(2),
      from: '',
      upTo: '',
      markup: ''
    }
  ]
}