import React, { useEffect, useState } from 'react';
import { AMIButton, AMIFormElement, AMIInput } from '../../ui-components/UiComponents';
import { isValidEmail } from '../../utilities/ValidationUtilities';
import logo from './express-fr8-logo.png';
import { challengeAuth, verifyUser } from '../cognitoAuthService';
import { useHistory } from 'react-router-dom';

const ExpressFr8InitialSetup = () => {

  const history = useHistory();

  const [showErrors, setShowErrors] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);
  const [showSetPasswordScreen, setShowSetPasswordScreen] = useState(false);

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const email = params.get('email') as string;
    const name = params.get('name') as string;
    setEmail(email);
    setName(name);
  }, [])

  const onContinueClick = async() => {
    setIsSpinning(true);
    setShowErrors(true);
    const validEmail = isValidEmail(email);
    if (validEmail && password) {
      try {
        const result = await verifyUser(email, password);
        if (result) {
          setShowSetPasswordScreen(true);
          setShowErrors(false);
        }
      } catch (error) {
        alert(`Sign in failed: ${error}`);
      }
    }
    setIsSpinning(false);
  }

  const onSetPasswordClick = async() => {
    setIsSpinning(true);
    setShowErrors(true);
    const passwordsMatch = password === confirmPassword;
    if (passwordsMatch) {
      try {
        await challengeAuth(email, password);
        alert("Password successfully updated!\nSign in on next page.");
        history.push('/auth');
      } catch (error) {
        alert(`Change Password failed: ${error}`);
      }
    }
    setIsSpinning(false);
  }

  return (
    <div className="auth-page">

      <div className="auth-container">

        <img className="auth-container__logo" src={logo} alt="Logo"/>

        {!showSetPasswordScreen && (
          <>
           <p className="auth-container__text">Welcome {name}, please enter the <strong>temporary password</strong> from you <strong>invitation email</strong>.</p>

          <AMIFormElement
            label="Email"
            errorMessage={showErrors && !isValidEmail(email) ? "Please enter a valid email address" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-1"
              size="large"
              value={email}
              disabled
              onChange={() => null}
            />
          </AMIFormElement>

          <AMIFormElement
            label="Temporary Password"
            errorMessage={showErrors && !password ? "Please enter the temporary password form the invitation email" : ""}
            style={{width: "100%"}}
          >
            <AMIInput
              style={{width: '100%'}}
              name="input-2"
              size="large"
              type="password"
              onChange={(event) => setPassword(event.target.value)}
            />
          </AMIFormElement>

            <AMIButton
              variant="primary"
              size="large"
              type="submit"
              style={{width: "100%"}}
              isSpinning={isSpinning}
              onClick={onContinueClick}
            >
              Continue
            </AMIButton>
          </>
        )}

{/* Set New Password */}
        {showSetPasswordScreen && (
          <>
            <AMIFormElement
              label="Email"
              errorMessage={showErrors && !isValidEmail(email) ? "Please enter a valid email address" : ""}
              style={{width: "100%"}}
            >
              <AMIInput
                style={{width: '100%'}}
                name="email"
                size="large"
                value={email}
                disabled
                onChange={(event) => setEmail(event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="New Password"
              errorMessage={showErrors && !password ? "Required" : ""}
              style={{width: "100%"}}
            >
              <AMIInput
                style={{width: '100%'}}
                name="password"
                size="large"
                type="password"
                onChange={(event) => setPassword(event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Confirm New Password"
              errorMessage={
                showErrors && confirmPassword !== password
                  ? "Passwords do not match"
                  : ""
                }
              style={{width: "100%"}}
            >
              <AMIInput
                style={{width: '100%'}}
                name="confirm-password"
                size="large"
                type="password"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </AMIFormElement>

            <AMIButton
              variant="primary"
              size="large"
              type="submit"
              style={{width: "100%"}}
              isSpinning={isSpinning}
              onClick={onSetPasswordClick}
            >
              Continue
            </AMIButton>
          </>
        )}
      </div>

    </div>
  )
}

export default ExpressFr8InitialSetup;