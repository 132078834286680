import React, { useEffect, useState } from 'react';
import './App.scss';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import HomePage from './pages/single-booking/home-page/HomePage';
import QuotesPage from './pages/single-booking/quotes-page/QuotesPage';
import DetailsPage from './pages/single-booking/details-page/DetailsPage';
import ConfirmPage from './pages/single-booking/confirm-page/ConfirmPage';
import FinishPage from './pages/single-booking/finish-page/FinishPage';
import { appDataActions, appDataSelector, PageStatus } from './features/appDataSlice';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Auth from './auth/Auth';
import Header from './components/header/Header';
import useWindowDimensions from './hooks/useWindowDimensions';
import Footer from './components/footer/Footer';
import MobileNavigation from './components/mobile-navigation/MobileNavigation';
import { customerDetailsSelector } from './features/customerSlice';
import TrackingPage from './pages/tracking/TrackingPage';
import store from "./app/store";
import { saveState } from "./app/browser-storage";
import InactivityModal from './components/inactivity-modal/InactivityModal';
import ContactPage from './pages-ancillary/contact-page/ContactPage';
import FaqPage from './pages-ancillary/faq/FaqPage';
import AccountPage from './pages/account-page/AccountPage';
import ChatWidget from './components/app/ChatWidget'
import ApiPage from './pages/api/ApiPage';
import Manifest from './pages/bulk-booking/Manifest';
import PreviewWidget from './components/preview-widget/PreviewWidget';
import { defaultColors, setDocumentColors } from './pages/account-page/white-label/WhiteLabelUtils';
import packageJS from '../package.json'
import { getRule } from "./utilities/RulesEngineUtilities";

const debounce = require('debounce');

store.subscribe(
  debounce(() => {
    saveState(store.getState())
      .catch(error => console.error(error))
  }, 800)
)

function App() {

  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const dispatch = useAppDispatch();

  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState<boolean>(false);

  const location = useLocation().pathname;
  const isWlDomainOrigin = appData.isWl;

  const {width} = useWindowDimensions();

  useEffect(() => {
    if (appData.customColors) setDocumentColors(appData.customColors);
    if (isWlDomainOrigin && !appData.customColors) setDocumentColors(defaultColors);
  }, [])

  useEffect(() => {
    if (isWlDomainOrigin) {
      document.getElementById("favicon")?.setAttribute('href', '/favicon2.ico');
      document.title = 'Express Fr8';
      return;
    }
    if (customer.auth.accessToken) {
      if (customer.customerCountryCode === 'US') {
        document.title = 'AMI quick2ship';
      } else {
        document.title = 'AMI click2ship'
      }
    } else {
      document.title = 'AMI'
    }
  }, [customer.customerCountryCode]);

  useEffect(() => {
    if (window.location.href.includes('expressfr8')) dispatch(appDataActions.setIsWhiteLabel(true));
    else dispatch(appDataActions.setIsWhiteLabel(false))
  }, [location]);

  return (
    <div className="app">

      {customer.auth.accessToken && <InactivityModal />}

      {appData.previewMode && <PreviewWidget />}

      {location !== "/auth"
        && !location.includes("/api")
        && !location.includes("initial-setup")
        && !location.includes("reset-password")
        && <Header customRules={appData.customRules}/>
      }
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>

        {customer.auth.accessToken && <Route path="/single-booking">
          <HomePage />
        </Route>}

        {customer.auth.accessToken
          && getRule(appData.customRules, 'bulkBookingVisible')
          && <Route path="/bulk-booking">
              <Manifest />
             </Route>
        }

        {appData.homePageStatus === PageStatus.COMPLETE
        && <Route path="/quote">
          <QuotesPage />
        </Route>}

        {appData.homePageStatus === PageStatus.COMPLETE
        && <Route path="/details">
          <DetailsPage />
        </Route>}

        {appData.homePageStatus === PageStatus.COMPLETE
        && <Route path="/confirm">
          <ConfirmPage />
        </Route>}

        {appData.confirmPageStatus === PageStatus.COMPLETE
        && <Route path="/finish">
          <FinishPage />
        </Route>}

        <Route path="/tracking">
          <TrackingPage />
        </Route>

        <Route path="/contact">
          <ContactPage />
        </Route>

        <Route path="/faq">
          <FaqPage />
        </Route>

        <Route path="/api">
          <ApiPage />
        </Route>

        <Route path="/account">
          <AccountPage />
        </Route>

        <Route path="*">
          <Redirect to="/auth"/>
        </Route>
      </Switch>

      {width > 900
        && !isWlDomainOrigin
        && !location.includes("initial-setup")
        && !location.includes("reset-password")
        && <Footer />
      }

      {width > 900 && isWlDomainOrigin && <p className="wl-version-number">{packageJS.version}</p>}

      {width < 901
        && location !== '/auth'
        &&  <div className="mobile-navigation-container">
              <MobileNavigation onNavigationOpen={(event) => setIsMobileNavigationOpen(event)}/>
            </div>
      }

      {!isWlDomainOrigin
        && customer.auth.accessToken
        && !isMobileNavigationOpen
        && <ChatWidget customer={customer}/>
      }

    </div>
  );
}

export default App;